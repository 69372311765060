import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { countries } from '../countries';
import { CurrencyModel, currencies } from '../currencies';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterData } from '../../warmi/accounts-receivable/model/filter-data.model';
import { InteractionStatus, InteractionStatusIds } from '../../warmi/accounts-receivable/model/interaction-status.model';
import { AdvisersAndSupervisorsResponse } from '../../warmi/accounts-receivable/model/userProjectWithDataResponse.model';
import { DiscountType } from '../../warmi/accounts-receivable/model/recoveryCampaign.model';
import { AccountSettings, BankAccountType, ValidationsStatus } from '../../warmi/my-account/model/account-settings.models';
import { AssignmentConfiguration, AssignmentPopulations, Intersection } from '../../warmi/strategies/model/assignmentConfiguration.model';
import { ClientGroupedByProject, ClientGroupedData } from '../../warmi/advisers/model/clientGroupedData';
import { AssignmentPopulation } from '../../warmi/advisers/model/AssignmentPopulation';
import { FieldManagementActionType } from '../../warmi/conversation/model/field-management.model';
import { CallManagementActionType } from '../../warmi/call/model/call-management.model';
import { CommunicationType, Interaction, InteractionPageable, InteractionStatusEnum } from '../../warmi/accounts-receivable/model/interaction.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CountryCode } from '../../warmi/general/model/country.model';
import { PlanConfiguration } from '../../warmi/strategies/model/plan.model';
import { UserProjectPermission } from '../../warmi/my-account/model/user-project-permissions.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChannelType } from '../../warmi/strategies/model/template.model';
import { VerificationAlert } from '../../warmi/credit/model/credit-verification-configuration.model';

const INTERACTION_BACKEND_URI = `${environment.backendUri}/interaction`;
const API_INVOICE_URL = `${environment.backendUri}/invoice`;
const API_AUTH_TOKEN_KEY = `${environment.authTokenKey}`;

@Injectable({
  providedIn: 'root'
})
export class MoonflowUtilService {

  private helper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private _translateService: TranslateService,
    ) { }


  formatBytes(bytes : number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['kb', 'kb', 'mb', 'gb', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  getCurrencies() : CurrencyModel[] {
    let primaryCurrency = this.decryptedToken()?.primaryCurrency;
    let secondaryCurrency = this.decryptedToken()?.secondaryCurrency;
    let filteredCurrencies = currencies.filter(x => !x.showOnlyIfMatchesSecondaryCurrency || (x.showOnlyIfMatchesSecondaryCurrency && x.code == secondaryCurrency));
    filteredCurrencies.sort((a, b) => {
        if (a.code === primaryCurrency) {
            return -1;
        } else if (a.code === secondaryCurrency && b.code !== primaryCurrency) {
            return 1;
        } else {
            return 0;
        }
    });
  
    return filteredCurrencies;
  
  }

  getSecondaryCurrency() : string {
    return this.decryptedToken()?.secondaryCurrency || 'USD';
  }

  getBusinessClientLevels() : { key : number; label : string}[] {
    return [
      {key : 1, label : 'COMMON.LEVEL.LEVEL_1'},
      {key : 2, label : 'COMMON.LEVEL.LEVEL_2'},
      {key : 3, label : 'COMMON.LEVEL.LEVEL_3'},
      {key : 4, label : 'COMMON.LEVEL.LEVEL_4'},
      {key : 5, label : 'COMMON.LEVEL.LEVEL_5'},
      {key : 6, label : 'COMMON.LEVEL.LEVEL_6'},
      {key : 7, label : 'COMMON.LEVEL.LEVEL_7'},
      {key : 8, label : 'COMMON.LEVEL.LEVEL_8'},
      {key : 9, label : 'COMMON.LEVEL.LEVEL_9'},
      {key : 10, label : 'COMMON.LEVEL.LEVEL_10'},
    ]
  }

  public getClientLevels() : { value : number; label : string}[] {
    return [
      {value : 1, label : 'COMMON.LEVEL.LEVEL_1'},
      {value : 2, label : 'COMMON.LEVEL.LEVEL_2'},
      {value : 3, label : 'COMMON.LEVEL.LEVEL_3'},
      {value : 4, label : 'COMMON.LEVEL.LEVEL_4'},
      {value : 5, label : 'COMMON.LEVEL.LEVEL_5'},
      {value : 6, label : 'COMMON.LEVEL.LEVEL_6'},
      {value : 7, label : 'COMMON.LEVEL.LEVEL_7'},
      {value : 8, label : 'COMMON.LEVEL.LEVEL_8'},
      {value : 9, label : 'COMMON.LEVEL.LEVEL_9'},
      {value : 10, label : 'COMMON.LEVEL.LEVEL_10'},
    ]
  }

  getChannelOptions(){
    return [
      { label: 'COMMON.CHANNEL_TYPE.WHATSAPP', value: ChannelType.WHATSAPP_CHANNEL_TYPE },
      { label: 'COMMON.CHANNEL_TYPE.EMAIL', value: ChannelType.EMAIL_CHANNEL_TYPE },
      { label: 'COMMON.CHANNEL_TYPE.SMS', value: ChannelType.SMS_CHANNEL_TYPE },
      { label: 'COMMON.CHANNEL_TYPE.PHONE_CALL', value: ChannelType.PHONE_CALL },
      { label: 'COMMON.CHANNEL_TYPE.FIELD_MANAGEMENT', value: ChannelType.FIELD_MANAGEMENT },
      { label: 'COMMON.CHANNEL_TYPE.WHATSAPP', value: ChannelType.WHATSAPP_CLOUD_API_CHANNEL_TYPE },
    ]
  }

  setPhoneNumberValidationsByPrefix(prefix : string,  control : AbstractControl, isRequired? : boolean){
    let countryId = null;
    if(prefix != null) {
      let country = countries.find(e => e.phone == prefix);
      if(country) countryId = Number(country.id);
    }
    this.setPhoneNumberValidationsByCountry(countryId, control, isRequired);
  }

  setPhoneNumberValidationsByCountry(countryId: number | null, control : AbstractControl, isRequired? : boolean) {
    let validators = [Validators.pattern(new RegExp(/^[0-9]+$/))];
    if(isRequired) validators.push(Validators.required);
    if(countryId != null){
      switch (countryId) {
        case 604:
          validators.push(Validators.minLength(9));
          validators.push(Validators.maxLength(9));
          break;
        case 484:
          validators.push(Validators.minLength(10));
          validators.push(Validators.maxLength(10));
          break;
        case 32:
          validators.push(Validators.minLength(10));
          validators.push(Validators.maxLength(11));
          break;
        case 591:
          validators.push(Validators.minLength(8));
          validators.push(Validators.maxLength(8));
            break;
        case 170:
          validators.push(Validators.minLength(8));
          validators.push(Validators.maxLength(10));
          break;
        default:
          validators.push(Validators.minLength(8));
          validators.push(Validators.maxLength(15));
          break;
      }
    }
    this.clearValidators(control);
    this.setValidators(control, Validators.compose(validators));
  }

  private clearValidators(control: AbstractControl | null) {
    control?.clearValidators();
    control?.updateValueAndValidity();
  }

  private setValidators(
    control: AbstractControl | null,
    validators: ValidatorFn | ValidatorFn[] | null
  ) {
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  getCurrencySymbol(currency : string | null | undefined){
    if(!currency) return '';
    else{
      let currencyData = currencies.find(x => x.code == currency);
      if(currencyData) return currencyData?.symbol;
    }
    return currency;
  }

  getList(page: any,clientId: string | null,startDate:string | null,endDate:string | null,
    communicationFlowIds: any,campaignIds: any,paymentActionIds: any,
    channelTypes: any,interactionStatusIds: any,filterSearch: any,supervisorProjectUserIds : any,
    adviserProjectUserIds : any,IsInteractionComponenet:boolean,portfolioTypes:any,validChannelActive:boolean,
    clientIds : any,actionType: any,processes : any, communicationTypes : any): Observable<interactionpageable> {
    let param = new HttpParams();
    if (page != null) {param = param.append('page', page);}
    if (clientId != null && clientId.trim().length > 0) {param = param.append('clientId', clientId);}
    if (portfolioTypes != null && portfolioTypes.length > 0) {param = param.append('portfolioTypes', portfolioTypes);}
    if (startDate != null) {param = param.append('startDate', startDate);}
    if (endDate != null) {param = param.append('endDate', endDate);}
    if (communicationFlowIds != null && communicationFlowIds.length > 0) {param = param.append('communicationFlowIds', communicationFlowIds);}
    if (campaignIds != null && campaignIds.length > 0) {param = param.append('campaignIds', campaignIds);}
    if (processes != null && processes.length > 0) {param = param.append('processes', processes);}
    if (paymentActionIds != null && paymentActionIds.length > 0) {param = param.append('paymentActionIds', paymentActionIds);}
    if (channelTypes != null && channelTypes.length > 0) {
      param = param.append('channelTypes', channelTypes);
    }
    if (interactionStatusIds != null && interactionStatusIds.length > 0){param = param.append('interactionStatusIds', interactionStatusIds);}
    if (filterSearch != null && filterSearch.length > 0){param = param.append('search', filterSearch);}
    if (adviserProjectUserIds != null) {param = param.append('adviserProjectUserIds', adviserProjectUserIds);}
    if (supervisorProjectUserIds != null) {param = param.append('supervisorProjectUserIds', supervisorProjectUserIds);}
    if (validChannelActive != null) param = param.append('validChannelActive', validChannelActive);
    if (clientIds) param = param.append('clientIds', clientIds);
    if (actionType != null) param = param.append('actionType', actionType);
    if (communicationTypes != null && communicationTypes.length > 0) {param = param.append('communicationTypes', communicationTypes);}

    if(IsInteractionComponenet){
      return this.http.get<interactionpageable>(`${INTERACTION_BACKEND_URI}/byfilter`,{ params: param });
    }else{
      return this.http.get<interactionpageable>(`${API_INVOICE_URL}/interaction/byfilter`,{ params: param });
    }

  }

  getFilterData(IsInteractionComponenet:boolean) : Observable<{
    communicationFlows : FilterData[];
    campaigns : FilterData[];
    interactionStatuses : InteractionStatus[];
  }>{
    if(IsInteractionComponenet){
      return this.http.get<{
        communicationFlows : FilterData[];
        campaigns : FilterData[];
        interactionStatuses : InteractionStatus[];
      }>(`${INTERACTION_BACKEND_URI}/filters/all`);
    }else{
      return this.http.get<{
        communicationFlows : FilterData[];
        campaigns : FilterData[];
        interactionStatuses : InteractionStatus[];
      }>(`${API_INVOICE_URL}/interaction/filters/all`);
    }


  }

  getAdvisersAndSupervisors(IsInteractionComponenet:boolean): Observable<advisersandsupervisorsresponse> {

    if(IsInteractionComponenet){
      return this.http.get<advisersandsupervisorsresponse>(
        `${INTERACTION_BACKEND_URI}/advisersandsupervisors`
      );
    }else{
      return this.http.get<advisersandsupervisorsresponse>(
        `${API_INVOICE_URL}/interaction/advisersandsupervisors`
      );
    }


  }

  getAccountTypes() : {value : string, label : string}[] {
    return [
      {label : "ACCOUNT_TYPE.SAVINGS", value : BankAccountType.SAVINGS_ACCOUNT},
      {label : "ACCOUNT_TYPE.CHECKING", value : BankAccountType.CHECKING_ACCOUNT},
    ]
  }

   upload(file: File, url: string): Observable<httpevent<any>> {
    let headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('files', file);
    headers.append('Content-Type', file.type);
    const req = new HttpRequest('PUT', `${url}`, file, {
      headers: headers,
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req);
  }

  getRecoveryCampaignDiscountTypes(){
    return [
      {value : DiscountType.DISCOUNT_TYPE_PERCENTAGE, label : 'RECOVERY_CAMPAIGN_DISCOUNT_TYPE.PERCENTAGE'},
      {value : DiscountType.DISCOUNT_TYPE_AMOUNT, label : 'RECOVERY_CAMPAIGN_DISCOUNT_TYPE.AMOUNT'},
    ];
  }

  getVerificationStatus(accountSettingsBackup: AccountSettings){
    if(!accountSettingsBackup) return 'N';
    if(accountSettingsBackup?.validations?.legalInformationStatus == ValidationsStatus.SUCCESS_STATUS &&
      accountSettingsBackup?.validations?.taxInformationStatus == ValidationsStatus.SUCCESS_STATUS &&
      accountSettingsBackup?.validations?.bankInformationStatus == ValidationsStatus.SUCCESS_STATUS) return 'S';
      if(accountSettingsBackup.validations){
        if(accountSettingsBackup.validations.legalInformationStatus == ValidationsStatus.SUCCESS_STATUS &&
          accountSettingsBackup.validations.taxInformationStatus == ValidationsStatus.SUCCESS_STATUS &&
          accountSettingsBackup.validations.bankInformationStatus == ValidationsStatus.SUCCESS_STATUS) return 'S';
        else if(
          ((accountSettingsBackup.validations.legalInformationStatus == ValidationsStatus.PENDING_STATUS && accountSettingsBackup.legalConstitutionValidationFile?.length && accountSettingsBackup.legalRepresentativeValidationFile?.length) || accountSettingsBackup.validations.legalInformationStatus == ValidationsStatus.SUCCESS_STATUS) &&
          ((accountSettingsBackup.validations.taxInformationStatus == ValidationsStatus.PENDING_STATUS && accountSettingsBackup.taxCertificateValidationFile?.length) || accountSettingsBackup.validations.taxInformationStatus == ValidationsStatus.SUCCESS_STATUS) &&
          ((accountSettingsBackup.validations.bankInformationStatus == ValidationsStatus.PENDING_STATUS && accountSettingsBackup.bankInformation?.length) || accountSettingsBackup.validations.bankInformationStatus == ValidationsStatus.SUCCESS_STATUS)) return 'P';
      }
      return 'N';
  }

  getGroupedData(intersections : Intersection[], assignmentPopulation : AssignmentPopulations | AssignmentPopulation | null, clientGroupedData: ClientGroupedByProject, assignmentConfiguration : AssignmentConfiguration,  recoveryCampaignData? : boolean) : {amount : number, currency : string | null, percentage : number, amountPercentage : number, count : number, loading : boolean} | null  {

    let data : {amount : number, currency : string | null, percentage : number, amountPercentage : number; count : number, loading : boolean} = {amount : 0, currency : null, percentage : 0, amountPercentage : 0, count : 0, loading : true};
    if(!clientGroupedData || (!intersections && !recoveryCampaignData)) return null;
    let populationsGroupedDataFiltered : ClientGroupedData[] = [];
    if(!recoveryCampaignData){
      let populationIds = intersections?.map(x => x.populationId) || [];
      let populationsGroupedData = clientGroupedData.clients.filter(e => populationIds.includes(e.populationId));
      if(!populationsGroupedData?.length){
        data.currency = clientGroupedData.currency;
        data.loading = false;
        if(assignmentPopulation) assignmentPopulation.countData = data;
        return data;
      }
      intersections.map(x => {
        let section = assignmentConfiguration.sections.find(z => z.index == x.sectionIndex) || null;
        if(section){
          let filtered = populationsGroupedData.filter(y => y.populationId == x.populationId && (section?.from != null && section.from != undefined ?  y.days >= (section as any).from : true) && (section?.to != null && section.to != undefined ? y.days <= (section as any).to : true));
          populationsGroupedDataFiltered = populationsGroupedDataFiltered.concat(filtered);
        }
      })
    }
    else{
      if(clientGroupedData?.campaignClients?.length) populationsGroupedDataFiltered = [].concat(clientGroupedData.campaignClients as any);
      data.currency = clientGroupedData.currencyCampaign;
    }
    data = {amount : 0, currency : data.currency, percentage : 0, amountPercentage : 0, count : 0, loading : false};
    data.currency = populationsGroupedDataFiltered.find(e => e.currency)?.currency || null;
    data.amount = populationsGroupedDataFiltered.map(x => x.amount).reduce((a, b) => a + b, 0);
    data.count = populationsGroupedDataFiltered.map(x => x.count).reduce((a, b) => a + b, 0);
    if(clientGroupedData.clientsTotalCount) data.percentage = (data.count*100)/clientGroupedData.clientsTotalCount;
    if(recoveryCampaignData && clientGroupedData.totalAmountCampaign) data.amountPercentage = (data.amount*100)/clientGroupedData.totalAmountCampaign;
    else if(!recoveryCampaignData && clientGroupedData.totalAmount) data.amountPercentage = (data.amount*100)/clientGroupedData.totalAmount;
    if(assignmentPopulation) assignmentPopulation.countData = data;
    return data;
  }

  getStatus(status: number | string, interactionStatuses : InteractionStatus[], fillEmpty?: boolean) {
    switch (status) {
      case InteractionStatusEnum.INTERACTION_STATUS_SEND: return this._translateService.instant('INTERACTION_STATUS.SEND');
      case InteractionStatusEnum.INTERACTION_STATUS_DELIVERY: return this._translateService.instant('INTERACTION_STATUS.DELIVERY');
      case InteractionStatusEnum.INTERACTION_STATUS_OPEN: return this._translateService.instant('INTERACTION_STATUS.OPEN');
      case InteractionStatusEnum.INTERACTION_STATUS_GENERATED: return this._translateService.instant('INTERACTION_STATUS.GENERATED');
      case InteractionStatusEnum.INTERACTION_STATUS_PROGRAMMED: return this._translateService.instant('INTERACTION_STATUS.PROGRAMMED');
      case InteractionStatusEnum.INTERACTION_STATUS_FAILED: return this._translateService.instant('INTERACTION_STATUS.FAILED');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT: return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGMENT_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_CONTACT: return this._translateService.instant('INTERACTION_STATUS.CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT: return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGMENT_NOT_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_NOT_CONTACT: return this._translateService.instant('INTERACTION_STATUS.NOT_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_TO_MANAGE: return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGMENT_TO_MANAGE');
      case InteractionStatusEnum.INTERACTION_STATUS_MISS_OUT: return this._translateService.instant('INTERACTION_STATUS.MISS_OUT');
      case InteractionStatusEnum.INTERACTION_STATUS_SKIPPED: return this._translateService.instant('INTERACTION_STATUS.SKIPPED');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGEMENT_INBOUND_CONTACT: return !fillEmpty ? "" : this._translateService.instant('INTERACTION_STATUS.CALL_MANAGEMENT_INBOUND_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_INBOUND: return !fillEmpty ? "" : this._translateService.instant('INTERACTION_STATUS.INBOUND');
      case InteractionStatusEnum.INTERACTION_STATUS_IN_PROCESS: return this._translateService.instant('INTERACTION_STATUS.IN_PROCESS');
      case InteractionStatusEnum.INTERACTION_STATUS_NO_RESPONSE_FROM_PROVIDER: return this._translateService.instant('INTERACTION_STATUS.NO_RESPONSE_FROM_PROVIDER');
      case InteractionStatusEnum.INTERACTION_STATUS_CONTACT: return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGMENT_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_NOT_CONTACT: return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGMENT_NOT_CONTACT');
      case InteractionStatusEnum.INTERACTION_STATUS_INBOUND : return this._translateService.instant('INTERACTION_STATUS.CALL_MANAGEMENT_INBOUND_CONTACT');
    }
    let interactionStatus = interactionStatuses?.find(x => Number(x.id) == status);
    if (interactionStatus) return this._translateService.instant(interactionStatus.translationKey);
  }

  getInteractionStatusCallManagement(status: number | string){
    switch (status) {
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_NOT_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_IN_PROCESS:
        return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGEMENT_INBOUND_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_INBOUND:
        return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND');
    }
  }

  getInteractionStatusFieldManagement(status: number | string){
    switch (status) {
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT:
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT:
        case InteractionStatusEnum.INTERACTION_STATUS_CONTACT:
        return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND');
      case InteractionStatusEnum.INTERACTION_STATUS_CALL_MANAGEMENT_INBOUND_CONTACT:
        return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND');
    }
  }

  getStatusCallManagementSubStatus(status: string) {
    if (!status?.length) return '';
    switch (status) {
      case CallManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE:
      case CallManagementActionType.RETRY_CALL_ACTION_TYPE:
      case CallManagementActionType.REFUSE_TO_PAY_ACTION_TYPE:
      case CallManagementActionType.CANT_PAY_ACTION_TYPE:
      case CallManagementActionType.NO_RESPONSE_ACTION_TYPE:
      case CallManagementActionType.WRONG_PHONE_NUMBER_ACTION_TYPE:
      case CallManagementActionType.PAYMENT_RENEGOTIATION_TYPE:
      case CallManagementActionType.MESSAGE_IN_MAILBOX_TYPE:
      case CallManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE:
      case CallManagementActionType.REFINANCE_TYPE:
      case CallManagementActionType.PAYMENT_MADE_TYPE:
      case CallManagementActionType.PAYMENT_COMMITMENT_ACTION_TYPE:
      case CallManagementActionType.PAYMENT_IN_REVIEW_ACTION_TYPE:
      case CallManagementActionType.COMMUNICATION_REMINDER_TYPE:
      case CallManagementActionType.PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE:
      case CallManagementActionType.PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE:
      case CallManagementActionType.PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE:
        return "- " + this.getStatusCallManagementSubStatusOnlyDescription(status);
      default:
        return this.getStatusCallManagementSubStatusOnlyDescription(status);
    }
  }

  getStatusCallManagementSubStatusOnlyDescription(status: string) {
    if (!status?.length) return '';
    switch (status) {
      case CallManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.COMMITMENT_VERIFY');
      case CallManagementActionType.INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.COMMITMENT_VERIFY');
      case CallManagementActionType.RETRY_CALL_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.RETRY_CALL');
      case CallManagementActionType.REFUSE_TO_PAY_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.REFUSE_TO_PAY');
      case CallManagementActionType.CANT_PAY_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.CANT_PAY');
      case CallManagementActionType.NO_RESPONSE_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.NO_RESPONSE');
      case CallManagementActionType.WRONG_PHONE_NUMBER_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.WRONG_NUMBER');
      case CallManagementActionType.PAYMENT_RENEGOTIATION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION');
      case CallManagementActionType.INBOUND_PAYMENT_RENEGOTIATION_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION');
      case CallManagementActionType.MESSAGE_IN_MAILBOX_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.MESSAGE_IN_MAILBOX');
      case CallManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.CONTACT_WITH_THIRD_PARTY');
      case CallManagementActionType.INBOUND_REQUESTED_INFORMATION_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.REQUESTED_INFORMATION');
      case CallManagementActionType.INBOUND_CLAIM_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.CLAIM');
      case CallManagementActionType.REFINANCE_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.REFINANCE');
      case CallManagementActionType.INBOUND_REFINANCE_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.REFINANCE');
      case CallManagementActionType.PAYMENT_MADE_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_MADE');
      case CallManagementActionType.INBOUND_PAYMENT_MADE_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_MADE');
      case CallManagementActionType.PAYMENT_COMMITMENT_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_COMMITMENT');
      case CallManagementActionType.INBOUND_PAYMENT_COMMITMENT_ACTION_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_COMMITMENT');
      case CallManagementActionType.PAYMENT_IN_REVIEW_ACTION_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_IN_REVIEW');
      case CallManagementActionType.INBOUND_PAYMENT_IN_REVIEW_ACTION_TYPE: return this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAYMENT_IN_REVIEW');
      case CallManagementActionType.IN_PROCESS_TYPE: return "";
      case CallManagementActionType.COMMUNICATION_REMINDER_TYPE: return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.COMMUNICATION_REMINDER');
      case CallManagementActionType.PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE:
      case CallManagementActionType.PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE:
      case CallManagementActionType.PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE:
        return  this._translateService.instant('CALL_MANAGEMENT.ACTIONS.PAUSED_UNTIL');
    }
    return '';
  }

  getStatusFieldManagementSubStatus(status: string) {
    if (!status?.length) return '';
    switch (status) {
      case FieldManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE:
      case FieldManagementActionType.RETRY_CALL_ACTION_TYPE:
      case FieldManagementActionType.REFUSE_TO_PAY_ACTION_TYPE:
      case FieldManagementActionType.CANT_PAY_ACTION_TYPE:
      case FieldManagementActionType.NO_ANSWER_ACTION_TYPE:
      case FieldManagementActionType.WRONG_ADDRESS_ACTION_TYPE:
      case FieldManagementActionType.PAYMENT_RENEGOTIATION_TYPE:
      case FieldManagementActionType.MESSAGE_IN_MAILBOX_TYPE:
      case FieldManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE:
      case FieldManagementActionType.REFINANCE_TYPE:
      case FieldManagementActionType.RETRY_MANAGEMENT_ACTION_TYPE:
      case FieldManagementActionType.UPDATE_DATA_ACTION_TYPE:
        return "- " + this.getStatusFieldManagementSubStatusOnlyDescription(status);
      default:
        return this.getStatusFieldManagementSubStatusOnlyDescription(status);
    }
    return '';
  }

  getStatusFieldManagementSubStatusOnlyDescription(status: string) {
    if (!status?.length) return '';
    switch (status) {
      case FieldManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.COMMITMENT_VERIFY');
      case FieldManagementActionType.INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.COMMITMENT_VERIFY');
      case FieldManagementActionType.RETRY_CALL_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.RETRY_CALL');
      case FieldManagementActionType.REFUSE_TO_PAY_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.REFUSE_TO_PAY');
      case FieldManagementActionType.CANT_PAY_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.CANT_PAY');
      case FieldManagementActionType.NO_ANSWER_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.NO_ANSWER');
      case FieldManagementActionType.WRONG_ADDRESS_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.WRONG_ADDRESS');
      case FieldManagementActionType.PAYMENT_RENEGOTIATION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION');
      case FieldManagementActionType.INBOUND_PAYMENT_RENEGOTIATION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION');
      case FieldManagementActionType.MESSAGE_IN_MAILBOX_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.MESSAGE_IN_MAILBOX');
      case FieldManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.CONTACT_WITH_THIRD_PARTY');
      case FieldManagementActionType.INBOUND_REQUESTED_INFORMATION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.REQUESTED_INFORMATION');
      case FieldManagementActionType.INBOUND_CLAIM_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.CLAIM');
      case FieldManagementActionType.REFINANCE_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.REFINANCE');
      case FieldManagementActionType.INBOUND_REFINANCE_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.REFINANCE');
      case FieldManagementActionType.RETRY_MANAGEMENT_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.RETRY_MANAGEMENT');
      case FieldManagementActionType.UPDATE_DATA_ACTION_TYPE: return this._translateService.instant('FIELD_MANAGEMENT.ACTIONS.UPDATE_DATA');
    }
    return '';
  }


  getAddionalFilterForInteractions(interactionStatus : InteractionStatus[]){
    return [
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.RETRY_CALL_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.RETRY_CALL_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.REFUSE_TO_PAY_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.REFUSE_TO_PAY_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.CANT_PAY_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.CANT_PAY_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.PAYMENT_RENEGOTIATION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.PAYMENT_RENEGOTIATION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.REFINANCE_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.REFINANCE_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.PAYMENT_MADE_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.PAYMENT_MADE_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.COMMUNICATION_REMINDER_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.COMMUNICATION_REMINDER_TYPE},

      {label : "%TYPE%: %INTERACTION_STATUS% - %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant("CALL_MANAGEMENT.ACTIONS.PAUSED_UNTIL_DESCRIPTION")
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE},

      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.NO_RESPONSE_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT || '', additionalValue : CallManagementActionType.NO_RESPONSE_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.WRONG_PHONE_NUMBER_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT || '', additionalValue : CallManagementActionType.WRONG_PHONE_NUMBER_ACTION_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.MESSAGE_IN_MAILBOX_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT || '', additionalValue : CallManagementActionType.MESSAGE_IN_MAILBOX_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT || '', additionalValue : CallManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE},
      {label : "%TYPE%: %INTERACTION_STATUS% - %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.OUTBOUND'))
      .replace("%INTERACTION_STATUS%", this._translateService.instant(this.getStatus(InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT.toString(), interactionStatus)))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant("CALL_MANAGEMENT.ACTIONS.PAUSED_UNTIL_DESCRIPTION")
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT || '', additionalValue : CallManagementActionType.PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_PAYMENT_RENEGOTIATION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_PAYMENT_RENEGOTIATION_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_REFINANCE_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_REFINANCE_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_REQUESTED_INFORMATION_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_REQUESTED_INFORMATION_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_CLAIM_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_CLAIM_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant(this.getStatusCallManagementSubStatus(CallManagementActionType.INBOUND_PAYMENT_MADE_TYPE))
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.INBOUND_PAYMENT_MADE_TYPE},
      {label : "%TYPE%: %ADDITIONAL_STATUS%"
      .replace("%TYPE%", this._translateService.instant('CALL_MANAGEMENT.ACTIONS.INBOUND'))
      .replace("%ADDITIONAL_STATUS%", this._translateService.instant("CALL_MANAGEMENT.ACTIONS.PAUSED_UNTIL_DESCRIPTION")
      ), value: InteractionStatusIds.INTERACTION_STATUS_CALL_MANAGMENT_CONTACT || '', additionalValue : CallManagementActionType.PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE},
    ];
  }

  isPausedLastActionType(status: string){
    return status == CallManagementActionType.PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE
      || status == CallManagementActionType.PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE
      || status == CallManagementActionType.PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE
  }

  convertPhoneNumberToCountry(countryCode: string, phoneNumber: string | null, prefix: string | null): string | null {
    if (phoneNumber == null || countryCode == null) return phoneNumber;
    if (phoneNumber.startsWith("+")) return phoneNumber;
    if(prefix == null){
      switch (countryCode) {
        case CountryCode.ARGENTINA_COUNTRY_CODE:
          prefix = "+54";
          break;
        case CountryCode.MEXICO_COUNTRY_CODE:
          prefix = "+52";
          break;
        case CountryCode.PERU_COUNTRY_CODE:
          prefix = "+51";
          break;
        case CountryCode.PANAMA_COUNTRY_CODE:
          prefix = "+507";
          break;
      }
    }
    if (prefix == null && countryCode != null) {
      let countriesData = countries.find(e => e.id == countryCode);
      prefix = countriesData?.phone || null;
    }
    if (prefix != null) {
      if (!prefix.startsWith("+")) prefix = "+" + prefix;
      if (!phoneNumber.startsWith(prefix)) phoneNumber = prefix + phoneNumber;
    }
    return phoneNumber;
  }

  convertSecondsToFormat(conversationSeconds : number | null) : string{
    return moment.utc((conversationSeconds || 0)*1000).format('HH:mm:ss');
  }


  public getLanguage() : { value : string; label : string}[] {
    return [
      {value : 'es', label : 'LANGUAGE.SPANISH'},
      {value : 'en', label : 'LANGUAGE.ENGLISH'},
      {value : 'pt-BR', label : 'LANGUAGE.PORTUGUESE_BR'}
    ]
  }

  getBooleanValueFromJsonFilteredByKeysWithDots(data : {[x : string] : any}, key : string) : boolean {
    if(!key || !key.length) return false;
    let keyList = key.split(".");
    let keyBase = keyList[0]+"";
    if(!data?.hasOwnProperty(keyBase)) return false;
    if(keyList.length == 1){
      if(typeof data[keyBase] == "boolean") return data[keyBase];
      else return false;
    }
    keyList.splice(0, 1);
    return this.getBooleanValueFromJsonFilteredByKeysWithDots(data[keyBase], keyList.join("."));
  }

  canAccessPlanBenefit(keys : string[], configuration : PlanConfiguration){
    if(!configuration) return false;
    for (const key of keys) {
      if(!this.getBooleanValueFromJsonFilteredByKeysWithDots(configuration as any, key)) return false;
    }
    return true;
  }

  decryptedToken(): {
    sub: string;
    roleId: any;
    permissions : UserProjectPermission[] | null;
    projectId?: string | null;
    canCall : boolean;
    userId : string;
    projectType : string;
    timeZone?: string | null;
    countryId: string;
    economicSectorId : number | null;
    chargeEntity : string | null;
    primaryCurrency : string | null;
    secondaryCurrency : string | null;
    pageComponentsConfiguration : string | null;
    planConfiguration : PlanConfiguration | null;
    allowIndicators : boolean | false;
    callProvider : string | null;
    isSuperadmin : boolean | false;
  } | null {
    const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
    if (token === 'undefined' || !token) {
      localStorage.removeItem(API_AUTH_TOKEN_KEY);
      return null;
    }
    let decryptedToken = this.helper.decodeToken(token);
    const activeProjectId = this.getActiveProjectId();
    if(decryptedToken?.permissions){
      try{
        decryptedToken.permissions = JSON.parse(decryptedToken.permissions)
      }catch(err){
        if(environment.production) console.error(err);
      }
    }
    if(decryptedToken?.planConfiguration){
      try{
        decryptedToken.planConfiguration = JSON.parse(decryptedToken.planConfiguration)
      }catch(err){
        if(environment.production) console.error(err);
      }
    }
    if ((!activeProjectId && decryptedToken?.projectId) || (activeProjectId && decryptedToken?.projectId && activeProjectId != decryptedToken?.projectId))
      sessionStorage.setItem(
        environment.activeProjectCookieKey,
        decryptedToken?.projectId
      );
    return decryptedToken;
  }

  getActiveProjectId(cookieKey?: string) {
    const projectId = sessionStorage.getItem(
      cookieKey || environment.activeProjectCookieKey
    );
    return projectId;
  }

  getListCallManagmentActions(){
   return [
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_COMMITMENT', value: CallManagementActionType.INBOUND_PAYMENT_COMMITMENT_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_IN_REVIEW', value: CallManagementActionType.INBOUND_PAYMENT_IN_REVIEW_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION', value: CallManagementActionType.INBOUND_PAYMENT_RENEGOTIATION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.REFINANCE', value: CallManagementActionType.INBOUND_REFINANCE_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.REQUESTED_INFORMATION', value: CallManagementActionType.INBOUND_REQUESTED_INFORMATION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.CLAIM', value: CallManagementActionType.INBOUND_CLAIM_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_MADE', value: CallManagementActionType.INBOUND_PAYMENT_MADE_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAUSE_COMMUNICATIONS', value: CallManagementActionType.PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE },

      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_COMMITMENT', value: CallManagementActionType.PAYMENT_COMMITMENT_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_IN_REVIEW', value: CallManagementActionType.PAYMENT_IN_REVIEW_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.RETRY_CALL', value: CallManagementActionType.RETRY_CALL_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.REFUSE_TO_PAY', value: CallManagementActionType.REFUSE_TO_PAY_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.CANT_PAY', value: CallManagementActionType.CANT_PAY_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_RENEGOTIATION', value: CallManagementActionType.PAYMENT_RENEGOTIATION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.REFINANCE', value: CallManagementActionType.REFINANCE_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_MADE', value: CallManagementActionType.PAYMENT_MADE_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.COMMUNICATION_REMINDER', value: CallManagementActionType.COMMUNICATION_REMINDER_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAUSE_COMMUNICATIONS', value: CallManagementActionType.PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE },

      { label: 'CALL_MANAGEMENT.ACTIONS.NO_RESPONSE', value: CallManagementActionType.NO_RESPONSE_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.WRONG_NUMBER', value: CallManagementActionType.WRONG_PHONE_NUMBER_ACTION_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.MESSAGE_IN_MAILBOX', value: CallManagementActionType.MESSAGE_IN_MAILBOX_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAYMENT_MADE', value: CallManagementActionType.PAYMENT_MADE_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.CONTACT_WITH_THIRD_PARTY', value: CallManagementActionType.CONTACT_WITH_THIRD_PARTY_TYPE },
      { label: 'CALL_MANAGEMENT.ACTIONS.PAUSE_COMMUNICATIONS', value: CallManagementActionType.PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE }
    ];
  }

  getProcessFilter() : { label: string; value: string }[]{
    return [
      {label : "COMMON.CLIENT_VERIFICATION_PROCESS", value : "CV"}
    ]
  }

  getCommunicationTypesFilter() : { label: string; value: string }[]{
    return [
      {label : "COMMON.COMMUNICATION_TYPES.INBOUND", value : CommunicationType.INBOUND_TYPE},
      {label : "COMMON.COMMUNICATION_TYPES.OUTBOUND", value : CommunicationType.OUTBOUND_TYPE},
    ]
  }

  getVerificationAlertEnumKeyByValue(value: string): string | null {
    const entries = Object.entries(VerificationAlert) as [string, string][];
    for (let [key, enumValue] of entries) {
      if (enumValue === value) {
        return key;
      }
    }
    return null;
  }

}
</httpevent<any></advisersandsupervisorsresponse></advisersandsupervisorsresponse></advisersandsupervisorsresponse></interactionpageable></interactionpageable></interactionpageable>